<template>
    <!-- Sidebar component, swap this element with another sidebar if you like -->
    <div
        class="flex grow flex-col gap-y-5 overflow-y-auto bg-white dark:bg-gray-900 px-6 pb-4 border-r border-gray-200 dark:border-gray-700">
        <div class="flex h-16 shrink-0 items-center">
            <NuxtLink to="/">
                <LogoImage class="h-5" />
            </NuxtLink>
        </div>
        <nav class="flex flex-1 flex-col">
            <ul
                role="list"
                class="flex flex-1 flex-col gap-y-7">
                <li
                    v-for="section in sidebarMenu"
                    v-show="section.ability ? $can(section.ability[0], section.ability[1]) : true"
                    :key="section.title">
                    <div
                        v-if="section.title !== 'default'"
                        class="text-xs font-semibold leading-6 text-gray-400 mb-2">
                        {{ $t(section.title) }}
                    </div>
                    <ul
                        role="list"
                        class="-mx-2 space-y-1">
                        <li
                            v-for="item in section.menu"
                            v-show="item.ability ? $can(item.ability[0], item.ability[1]) : true"
                            :key="item.title">
                            <NuxtLink
                                v-if="!item.children"
                                :to="localeRoute(item.path)"
                                class="group"
                                :class="[item.current ? 'bg-gray-50 text-primary-600' : 'text-gray-700 dark:text-gray-300 hover:text-primary-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']"
                                @click="layoutStore.isSidebarOpen = false">
                                <Icon
                                    v-if="item.icon"
                                    :name="item.icon"
                                    class="group-[.router-link-active]:text-primary-600"
                                    :class="[item.current ? 'text-primary-600' : 'text-gray-400 group-hover:text-primary-600', 'h-6 w-6 shrink-0']"
                                    aria-hidden="true" />
                                <span
                                    v-else
                                    class="group-[.router-link-exact-active]:text-primary-600"
                                    :class="[item.current ? 'text-primary-600 border-primary-600' : 'text-gray-400 border-gray-200 group-hover:border-primary-600 group-hover:text-primary-600', 'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white']">{{
                                        item.initial
                                    }}</span>
                                <span class="group-[.router-link-exact-active]:text-primary-600">
                                    {{ $t(item.title) }}
                                </span>
                            </NuxtLink>
                            <Disclosure
                                v-else
                                v-slot="{ open }"
                                as="div">
                                <DisclosureButton
                                    :class="[item.current ? 'bg-gray-50 text-primary-600' : 'text-gray-700 dark:text-gray-300 hover:text-primary-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800', 'group flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-700 dark:text-gray-300']">
                                    <Icon
                                        :name="item.icon"
                                        class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-primary-600"
                                        aria-hidden="true" />
                                    {{ $t(item.title) }}
                                    <Icon
                                        name="heroicons:chevron-right"
                                        :class="[open ? 'rotate-90 text-gray-500' : 'text-gray-400', 'ml-auto h-5 w-5 shrink-0 transition-transform duration-150 ease-in-out']"
                                        aria-hidden="true" />
                                </DisclosureButton>
                                <DisclosurePanel
                                    as="div"
                                    class="mt-1 px-2">
                                    <ul>
                                        <li
                                            v-for="subItem in item.children"
                                            v-show="subItem.ability ? $can(subItem.ability[0], subItem.ability[1]) : true"
                                            :key="subItem.title">
                                            <NuxtLink
                                                :to="localeRoute(subItem.path)"
                                                class="group"
                                                :class="[subItem.current ? 'bg-gray-50 dark:bg-gray-900 text-primary-600' : 'text-gray-700 dark:text-gray-300 hover:text-primary-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800', 'block rounded-md py-2 pr-2 pl-9 text-sm leading-6']"
                                                @click="layoutStore.isSidebarOpen = false">
                                                <span class="group-[.router-link-active]:text-primary-600">
                                                    {{ $t(subItem.title) }}
                                                </span>
                                            </NuxtLink>
                                        </li>
                                    </ul>
                                </DisclosurePanel>
                            </Disclosure>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script setup>
import {useLayoutStore} from "@/store/layout";
const layoutStore = useLayoutStore()
</script>