import {useLayoutStore} from "../store/layout";

export const $getCurrentModule = () => {
    const module = useLayoutStore().currentModule
    return module ? appModules[module] : null
}

export const $getModuleIcon = (module?: string): string|null => {
    if (!module) return $getCurrentModule()?.icon ?? null
    return appModules[module].icon ?? null
}

export const $getModuleColor = (module?: string): string|null => {
    if (!module) return $getCurrentModule()?.color ?? null
    return appModules[module].color ?? null
}

export const appModules: AppModules = {
    dashboard: {
        name: 'dashboard',
        icon: 'heroicons:home'
    },
    users: {
        name: 'users',
        icon: 'heroicons:users',
        color: 'indigo',
        search: {
            detail: 'users-id-userBaseData',
            nameProp: 'fullname'
        },
    },
    organisations: {
        name: 'organisations',
        icon: 'heroicons:user',
        color: 'teal',
        search: {
            detail: 'organisations-id-organisationBaseData'
        },
    },
    settings: {
        name: 'settings',
        icon: 'heroicons:cog-6-tooth',
    },
}
