<template>
    <div
        class="absolute top-0 sm:left-1/2 sm:-translate-x-1/2 w-full sm:w-auto bg-amber-500 text-black z-50 sm:p-1 shadow rounded rounded-t-none transform transition-transform"
        :class="{'translate-y-0':notificationSetting !== 'default','-translate-y-10':!notificationSetting !== 'default'}">
        <div class="flex text-xs font-bold justify-center">
            <strong>Möchtest du Push-Benachrichtigungen erhalten?</strong>
            <LoadingSpinner
                v-if="pending"
                size="sm" />
            <div
                v-else
                class="btn btn-secondary mx-3"
                @click="setNotificationSetting">
                Jetzt einstellen
            </div>
        </div>
    </div>
</template>

<script setup>
import {useAuthStore} from "@/store/auth";
import {$larafetch} from "~/utils/$larafetch";
import {useNuxtApp} from "nuxt/app";

const authStore = useAuthStore()
const notification = useNotification()
const {t: $t} = useI18n()
const {$apiRoute} = useNuxtApp()
const config = useRuntimeConfig()
const notificationSetting = useState('notificationSetting', () => ref(''))
const pending = ref(false)

onMounted(() => {

    if (!('Notification' in window)) {
        notificationSetting.value = 'denied'
        return
    }

    notificationSetting.value = Notification.permission ?? 'default'

    //when a user manually disables and re-enables notifications, we need to register the new subscription
    if(notificationSetting.value === 'granted') {
        navigator.serviceWorker.ready.then(async (registration) => {
            const subscription = await registration.pushManager.getSubscription()
            if(subscription === null) {
                subscribeUserToPush()
            }
        })
    }
})

const setNotificationSetting = () => {
    pending.value = true
    if (!('Notification' in window)) {
        notification.error('Dieser Browser unterstützt keine Push-Notifications.')
        notificationSetting.value = 'denied'
        return
    }
    Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
            notificationSetting.value = 'granted'
            subscribeUserToPush()
            return
        }
        notificationSetting.value = 'denied'
    })
}

const subscribeUserToPush = () => {
    navigator.serviceWorker.ready.then(registration => {
        const subscribeOptions = {
            userVisibleOnly: true,
            applicationServerKey: config.public.vapidPublicKey,
        };

        return registration.pushManager.subscribe(subscribeOptions);
    })
        .then(pushSubscription => {
            sendSubscriptionToBackEnd(pushSubscription);
        });
}

const sendSubscriptionToBackEnd = (subscription) => {
    $larafetch($apiRoute('spa.users.update', {
                   user: authStore.userData.id
               }),
               {
                   method: 'PUT',
                   body: {
                       ...authStore.userData,
                       mode: 'webPush',
                       web_push: subscription
                   }
               })
        .then(response => {
            notification.success('Du erhältst jetzt Push-Benachrichtigungen')
        })
        .catch(error => {
            notification.error('Push-Benachrichtigungen konnten nicht aktiviert werden')
        })
        .finally(() => {
            pending.value = false
        })
}

</script>

<style scoped>

</style>