export const releaseNotesDe: ReleaseNotes = [
    {
        release: "0.1.0",
        date: "2023-10-19",
        tags: [
            "Grundsystem"
        ],
        type: "minor",
        notes: [
            {
                "description": "Erste Fertigstellung des Grundsystems"
            }
        ]
    },
    {
        release: "0.0.0",
        date: "2023-09-01",
        tags: [
            "Projektstart"
        ],
        type: "major",
        notes: [
            {
                "description": "Das Projekt wurde aufgesetzt. Die Entwicklung kann beginnen!"
            }
        ]
    }
]