<template>
    <div>
        <Sidebar/>
        <NotificationSettingBanner/>
        <ImpersonateBanner/>
        <div class="lg:pl-72 h-screen overflow-auto">
            <Header/>
            <main>
                <div>
                    <!-- Your content -->
                    <slot/>
                </div>
            </main>
        </div>
        <RequestLog/>
    </div>
</template>

<script setup>
import Header from "@/components/layouts/Header";
import Sidebar from "@/components/layouts/Sidebar";
import NotificationSettingBanner from "@/components/layouts/NotificationSettingBanner";
import ImpersonateBanner from "@/components/layouts/ImpersonateBanner";
import {useWebsocketStore} from "@/store/websockets";

onMounted(() => {
    useWebsocketStore().init()
})

useHead({
    bodyAttrs: {
        class: 'h-full'
    },
    htmlAttrs: {
        class: 'h-full'
    }
})

// Comment out to deactivate the help pages
useHelpPage().listenForHelp()
</script>
