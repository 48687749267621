<template>
    <TransitionRoot
        as="template"
        :show="model">
        <Dialog
            as="div"
            class="relative z-50"
            @close="model = false">
            <div class="fixed inset-0" />

            <div class="fixed inset-0 overflow-hidden">
                <div class="absolute inset-0 overflow-hidden">
                    <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                        <TransitionChild
                            as="template"
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enter-from="translate-x-full"
                            enter-to="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leave-from="translate-x-0"
                            leave-to="translate-x-full"
                            @after-enter="() => teleportNotificationArea = true"
                            @before-leave="() => teleportNotificationArea = false">
                            <DialogPanel
                                id="notificationAreaRoot"
                                class="pointer-events-auto w-screen max-w-md">
                                <div
                                    class="flex h-full flex-col overflow-y-scroll bg-white dark:bg-gray-900 py-6 shadow-xl">
                                    <div class="px-4 sm:px-6">
                                        <div class="flex items-start justify-between">
                                            <DialogTitle
                                                class="text-base font-semibold leading-6 text-gray-900">
                                                {{ $t('notifications.title') }}
                                            </DialogTitle>
                                            <div class="ml-3 flex h-7 items-center">
                                                <button
                                                    type="button"
                                                    class="relative rounded-md bg-white dark:bg-gray-900 text-gray-400 dark:text-gray-300 hover:text-gray-500 dark:hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:focus:ring-offset-gray-900"
                                                    @click="model = false">
                                                    <span class="absolute -inset-2.5" />
                                                    <span class="sr-only">Close panel</span>
                                                    <Icon
                                                        name="heroicons:x-mark"
                                                        class="h-6 w-6"
                                                        aria-hidden="true" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="relative mt-6 flex-1 px-4 sm:px-6 overflow-y-scroll">
                                        <!-- Notification feed-->
                                        <NotificationFeed />
                                    </div>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
import {useNotificationStore} from "@/store/notifications";

const notificationStore = useNotificationStore()

const teleportNotificationArea = useState('teleportNotificationArea')

// listen for new notifications
useEventBus('notifications').on((notification) => {
    notificationStore.refreshTimeline()
    useNotification().custom(
        notification.content,
        "heroicons:user-plus",
        "",
        "",
        "info")
})

const props = defineProps({
    modelValue: {
        type: Boolean,
        required: true,
    }
})

const emits = defineEmits(['update:modelValue'])

const model = computed({
    get: () => props.modelValue,
    set: (value) => emits('update:modelValue', value)
})
</script>